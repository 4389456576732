import { graphQLQuery } from './api';

export function loadLoggedInUserDetailsApi() {
  const queryString = `
  query {
    loggedInUser {
      id
      firstName
      lastName
      email
      subscription {
        type
        expiryDate
        upgradeRequested
      }
      jobTitle
      organisation {
        id
        type
        name
        address { shortDescription fullDescription}
        logo { id url }
        phone
        email
        description { short }
        owner { id firstName lastName jobTitle profileImage { url }}
        ... on Seller {
          recentActiveDate
          statistics {
            projectCount
            stockCount
            totalStockValue { type value }
          }
        }
      }
      profileImage {
        url
      }
      permissions
    }
  }`;
  const variables = {
  };

  return graphQLQuery(queryString, variables);
}

export function getLoggedInUserSubscriptionApi() {
  const queryString = `
  query {
    loggedInUser {
      subscription {
        type
      }
    }
  }`;
  const variables = {
  };

  return graphQLQuery(queryString, variables);
}

export function quickSearchSectionsApi() {
  const queryString = `
  query {
    quickSearchSections {
      name
      countryCode
      items {
        description
        locationQuery { searchItemId searchTerm searchType }
      }
    }
  }
  `;
  const variables = {};
  return graphQLQuery(queryString, variables);
}

export function sendTrialUpgradeRequestApi() {
  const mutationString = `
  mutation {
    sendTrialUpgradeRequest {
      succeeded
      data {
        type
        expiryDate
        upgradeRequested
      }
    }
  }`;
  const variables = {};
  return graphQLQuery(mutationString, variables);
}
